export const SET_VALUE = "SET_VALUE"
export const SET_MODULE_VALUE = "SET_MODULE_VALUE"
export const SET_INSTRUMENT = "SET_INSTRUMENT"
export const MOVE_MODULE = "MOVE_MODULE"
export const CREATE_CABLE = "CREATE_CABLE"
export const MOVE_CONNECTOR = "MOVE_CONNECTOR"
export const DRAG_CONNECTOR = "DRAG_CONNECTOR"
export const REMOVE_CONNECTOR = "REMOVE_CONNECTOR"
export const SET_DATA = "SET_DATA"
export const CREATE_MODULE = "CREATE_MODULE"
export const DELETE_MODULE = "DELETE_MODULE"
export const SET_LOGGED_IN = "SET_LOGGED_IN"
export const SET_LOADING = "SET_LOADING"
export const TOGGLE_DELETE = "TOGGLE_DELETE"
export const RESET_STATE = "RESET_STATE"
export const CREATE_PATCH = "CREATE_PATCH"
export const SET_CURRENT = "SET_CURRENT"
export const DELETE_PATCH = "DELETE_PATCH"
export const DELETE_INSTRUMENT = "DELETE_INSTRUMENT"
